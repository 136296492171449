export const customStyles = {
  rows: {
    style: {
      minHeight: "30px",
      "&:hover": {
        backgroundColor: "rgba(95, 158, 160, 0.1)", // Slightly lighter shade for row hover
      },
    },
  },
  headCells: {
    style: {
      backgroundColor: "#5f9ea0", // Cadet blue for header
      color: "white",
      paddingLeft: "5px",
      paddingRight: "5px",
      borderBottom: "2px solid rgba(0, 0, 0, 0.12)", // Bottom border for header cells
      borderRight: "1px solid rgba(0, 0, 0, 0.12)", // Right border for header cells
    },
  },
  headRow: {
    style: {
      minHeight: "28px",
      borderBottom: "2px solid rgba(0, 0, 0, 0.12)", // Ensure header row has bottom border
    },
  },
  cells: {
    style: {
      paddingLeft: "5px",
      paddingRight: "5px",
      fontSize: "12px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)", // Bottom border for data cells
      borderRight: "1px solid rgba(0, 0, 0, 0.12)", // Right border for data cells
      whiteSpace: "break-spaces",
    },
  },
  pagination: {
    style: {
      color: "inherit", // Default footer color, keeping it uncolored
    },
    pageButtonsStyle: {
      style: {
        color: "inherit",
      },
    },
  },
};
