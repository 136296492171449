import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { IoCloseSharp } from "react-icons/io5";
import { getPatientInfo } from 'services/http/PatientList/index'
import * as moment from "moment";
import { useLoader } from "services/loaderService";
import toast from "react-hot-toast";

function getRelationLabel(value) {
  const relationMap = {

    "1": "Self",
    "2": "Spouse",
    "3": "Child",
    "4": "Other",
    "5": "Individual",
    "6": "Organization",
    "7": "Employee",
  };
  return relationMap[value] ?? " ";
}

function getPatientTypeLabel(value) {
  const patientTypeMap = {

    "0": "Bicyclist",
    "1": "Driver",
    "2": "Passenger",
    "3": "Pedestrian",
  };
  return patientTypeMap[value] ?? " "; // Fallback to "Unknown" if the value is not in the map
}

function getPolicyGender(value) {
  const patientTypeMap = {

    "M": "Male",
    "F": "Female",

  };
  return patientTypeMap[value] ?? " "; // Fallback to "Unknown" if the value is not in the map
}



const PatientDetailModal = ({ closeModal, isOpen, caseNo, selectedRow }) => {

  const { showLoader, hideLoader } = useLoader();
  const [caseDetails, setCaseDetails] = useState({});


  useEffect(() => {
    getCaseDetails();
  }, [])

  const getCaseDetails = async () => {
    showLoader()
    const payload = {
      "companyId": selectedRow?.companyId,
      "caseId": caseNo,
    }
    try {
      let result = await getPatientInfo(payload);
      if (result != null) {
        if (result?.success) {
          setCaseDetails(result?.response?.receiveObj?.data?.data);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
        }
      }
      else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader();
      toast.error(error?.response?.receiveObj?.response?.data || "Something went wrong!");
      console.log(error);
    }
  }

  const formatPhoneNumber = (phone) => {
    if (!phone) return ''; // Handle null or undefined
    const cleaned = ('' + phone).replace(/\D/g, ''); // Remove non-digit characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
  }


  return (

    <Dialog open={isOpen} onClose={closeModal} className="relative w-screen z-10">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 w-screen flex items-center justify-center p-4 text-center">
        <DialogPanel transition className="custom-panel">
          <div className="modal-content">
            <DialogTitle
              as="h3"
              className="modal-title"
            >
              Case Information
              <IoCloseSharp
                className="float-right cursor-pointer mt-3"
                size={20}
                color="cadetblue"
                onClick={() => {
                  closeModal();
                }}
              />
            </DialogTitle>
            <div className="divider"></div>
            <div className="case-detail" >
              {/* Case Information Section */}
              <div>
                <InfoSection title="Case Information" style={{ marginTop: '2px' }} >
                  <InfoRow label="Case#" value={selectedRow?.caseNo} />
                  <InfoRow label="Case Type" value={selectedRow?.caseType} />
                  <InfoRow label="Case Status" value={selectedRow?.caseStatus} />
                  <InfoRow label="Attorney" value={caseDetails?.attorney?.firstName} />
                  <InfoRow label="Date Of First Treatment" value={caseDetails?.firstTreatmentDate ? moment(new Date(caseDetails?.firstTreatmentDate)).format("MM/DD/YYYY") : ''} />
                  <InfoRow label="Chart#" value={caseDetails?.chartNo} />
                  <InfoRow label="Report#" value={caseDetails?.reportNo} />
                </InfoSection>

                {/* Personal Information Section */}
                <InfoSection title="Personal Information">
                  <InfoRow label="Name" value={selectedRow?.patientName} />
                  <InfoRow label="D.O.B" value={caseDetails?.dob ? moment(new Date(caseDetails?.dob)).format("MM/DD/YYYY") : ''} />
                  <InfoRow label="Gender" value={caseDetails?.gender} />
                  <InfoRow label="Email" value={caseDetails?.email} />
                  <InfoRow label="Phone" value={formatPhoneNumber(caseDetails?.phone)} />
                  <InfoRow label="Work" value={formatPhoneNumber(caseDetails?.workPhone)} />
                  <InfoRow label="Address" value={
                    [
                      caseDetails?.address || '',
                      caseDetails?.city || '',
                      caseDetails?.stateName || '',
                      caseDetails?.zip || ''
                    ]
                      .filter(Boolean) // Filters out falsy values (null, undefined, empty string, etc.)
                      .join(', ') // Joins the remaining valid values with a comma and space
                  }

                  />
                </InfoSection>

                {/* Policy Holder Information Section */}
                <InfoSection title="Policy Holder Information">
                  <InfoRow label="Name" value={`${caseDetails?.policyHolder?.lastName || ''} ${caseDetails?.policyHolder?.firstName || ''}`.trim()} />
                  <InfoRow label="Gender" value={getPolicyGender(caseDetails?.policyHolder?.gender)} />
                  <InfoRow label="Address" value={
                    [
                      caseDetails?.policyHolder?.address || '',
                      caseDetails?.policyHolder?.city || '',
                      caseDetails?.policyHolder?.state || '',
                      caseDetails?.policyHolder?.zip || ''
                    ]
                      .filter(Boolean) // Filters out falsy values (null, undefined, empty string, etc.)
                      .join(', ') // Joins the remaining valid values with a comma and space
                  }
                  />
                  <InfoRow label="Relation to Patient" value={getRelationLabel(caseDetails?.relactionWithPatient)} />
                </InfoSection>

                {/* Insurance Information Section */}
                <InfoSection title="Insurance Information">
                  <InfoRow label="Name" value={caseDetails?.insurance?.name} />
                  <InfoRow label="Fax" value={caseDetails?.insuranceFax} />
                  <InfoRow label="Policy#" value={caseDetails?.policyNumber} />
                  <InfoRow label="Phone" value={formatPhoneNumber(caseDetails?.insurance?.phone)} />
                  <InfoRow label="Contact Person" value={formatPhoneNumber(caseDetails?.insurance?.phone)} />
                  <InfoRow label="Address" value={
                    [
                      caseDetails?.insurance?.address || '',
                      caseDetails?.insurance?.city || '',
                      caseDetails?.insurance?.state || '',
                      caseDetails?.insurance?.zip || ''
                    ]
                      .filter(Boolean) // Filters out falsy values (null, undefined, empty string, etc.)
                      .join(', ') // Joins the remaining valid values with a comma and space
                  }
                  />
                  <InfoRow label="Claim#" value={selectedRow?.claimNumber} />
                </InfoSection>

                {/* Accident Information Section */}
                <InfoSection title="Accident Information">
                  <InfoRow label="DOA" value={caseDetails?.accidentInfo?.accidentDate ? moment(new Date(caseDetails?.accidentInfo?.accidentDate)).format("MM/DD/YYYY") : ''} />
                  <InfoRow label="Plate#" value={caseDetails?.accidentInfo?.plateNo} />
                  <InfoRow label="Address" value={
                    [
                      caseDetails?.accidentInfo?.accidentAddress || '',
                      caseDetails?.accidentInfo?.accidentCity || '',
                      caseDetails?.accidentInfo?.accidentState || ''
                    ]
                      .filter(Boolean) // Filters out falsy values (null, undefined, empty string, etc.)
                      .join(', ') // Joins the remaining valid values with a comma and space
                  }
                  />
                  <InfoRow label="Report#" value={caseDetails?.accidentInfo?.reportNo} />
                  <InfoRow label="Hospital Name" value={caseDetails?.accidentInfo?.hospitalName} />
                  <InfoRow label="Hospital Address:" value={caseDetails?.accidentInfo?.hospitalAddress} />
                  <InfoRow label="Date Of Admission" value={caseDetails?.accidentInfo?.doa ? moment(new Date(caseDetails?.accidentInfo?.doa)).format("MM/DD/YYYY") : ''} />
                  <InfoRow label="Describe Injury" value={caseDetails?.accidentInfo?.describeInjury} />
                  <InfoRow label="Patient Type" value={getPatientTypeLabel(caseDetails?.accidentInfo?.patientType)} />
                </InfoSection>

                {/* Employer Information Section */}
                <InfoSection title="Employer Information">
                  <InfoRow label="Name" value={caseDetails?.employer?.name} />
                  <InfoRow label="Phone" value={formatPhoneNumber(caseDetails?.employer?.phone)} />
                  <InfoRow label="Address" value={
                    [
                      caseDetails?.employer?.address || '',
                      caseDetails?.employer?.city || '',
                      caseDetails?.employer?.state === "---Select---"||"--- Select ---" ? '' : caseDetails?.employer?.state || '',
                      caseDetails?.employer?.zip || ''
                    ]
                      .filter(Boolean) // Filters out falsy values (null, undefined, empty string, etc.)
                      .join(', ') // Joins the remaining valid values with a comma and space
                  }
                  />
                </InfoSection>

                {/* Adjuster Information Section */}
                <InfoSection title="Adjuster Information">
                  <InfoRow label="Name" value={caseDetails?.adjuster?.name} />
                  <InfoRow label="Phone" value={formatPhoneNumber(caseDetails?.adjuster?.phone)} />
                  <InfoRow label="Extension" value={caseDetails?.adjuster?.extension} />
                  <InfoRow label="FAX" value={caseDetails?.adjuster?.fax} />
                  <InfoRow label="Email" value={caseDetails?.adjuster?.email} />
                </InfoSection>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};
const InfoSection = ({ title, children, style }) => (
  <div className="info-section -mt-2" style={style}>
    <div className="section-header bg-cadetblue" >{title}</div>
    <div className="section-content grid grid-cols-4 gap-4">{children}</div>
  </div>
);

const InfoRow = ({ label, value }) => (
  <div className="info-row -mt-2 -mb-2">
    <b className="label">{label}:</b>
    <p className="value">{value}</p>
  </div>
)

export default PatientDetailModal;


