import React, { useEffect, useState, useRef } from "react";
import Table from "shared/Datatable";
import PatientListFilters from "./filters";
import { Link } from "react-router-dom";
import PatientDetailsModal from "./patientDetailsModal";
import { DocumentManager } from "./DocumentManagerModal";
import { HiOutlineDocumentText } from "react-icons/hi";
import Pagination from "shared/Pagination/Pagination";
import { getPatientList } from "services/http/PatientList";
import * as moment from "moment";
import { useLoader } from "services/loaderService";
import toast from "react-hot-toast";
import { reportsTableHeaders } from "./data";
import { arrayToExcel } from "shared/exportToExcel/ArrayToExcel";
import { getLocalStorage } from "services/storage";
import { getCaseType } from "services/http/Dropdown";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";


const PatientList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [patientListData, setPatientListData] = useState([]);
  const [casTypeData, setCasTypeData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [selectedRow, setSelectedRow] = useState({});

  const [searchValues, setSearchValues] = useState({
    searchText: '',
    selectedCaseType: '',
    selectedStatus: '',
    selectedAttorney: '',
    fromDate: null,
    toDate: null,
    duration: ''
  });

  // Ref to track initial render
  const isFirstRender = useRef(true);
  const profile = getLocalStorage('_profile')


  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setSelectedRow({})
    setIsOpen(false)
  };

  const openDocumentModal = () => setIsOpenDocument(true);
  const closeDocumentModal = () => setIsOpenDocument(false);

  const caseTypeList = async () => {
    showLoader()
    const payload = {
      "UserUid": profile?.userUid,
    }
    try {
      let result = await getCaseType(payload);
      if (result != null) {
        if (result?.success) {
          const options = result?.response?.receiveObj?.data?.data?.caseTypesRecords?.map(c => {
            return {
              value: c.caseType, label: c.caseType
            }
          })
          setCasTypeData(options);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
        }
      }
      else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader();
      toast.error(error?.response?.receiveObj?.response?.data || "Something went wrong!");
      console.log(error);
    }
  }

  const columns = [
    {
      name: "Case# ",
      selector: (row) => row.caseNo,
      sortable: true,
      width: "80px",
      center: true,
      cell: (row) => (
        <>
          <Link
            data-tag="allowRowEvents"
            onClick={() => {
              setSelectedRow(row)
              openModal();
            }}
            title={'Case Details'}
            style={{ color: "blue", textDecoration: "underline" }}
            to={""}
          >
            {row.caseNo}
          </Link>
          <HiOutlineDocumentText
            data-tag="allowRowEvents"
            onClick={() => {
              setSelectedRow(row)
              openDocumentModal();
            }}
            title="Document Manager"
            style={{
              color: "cadetblue",
              cursor: "pointer",
              marginLeft: 10
            }}
            size={16}
          />
        </>

      ),
    },
    {
      name: "Patient",
      selector: (row) => row?.patientName,
      sortable: true,
      width: "150px",
      cell: (row) => <span className="text-gray-700 font-sans text-left">{row?.patientName}</span>,
    },
    {
      name: "DOB",
      selector: (row) => row?.dob,
      sortable: true,
      width: "80px",
      cell: (row) => (
        <div style={{ textAlign: "right", width: "100%" }}>
          <span className="text-gray-700 font-sans">
            {row?.dob ? moment(new Date(row?.dob)).format("MM/DD/YYYY") : ''}
          </span>
        </div>
      ),
    },
    {
      name: "DOA",
      selector: (row) => row?.accidentDate,
      cell: (row) => (
        <div style={{ textAlign: "right", width: "100%" }}>
          <span className="text-gray-700 font-sans">
            {row?.accidentDate ? moment(new Date(row?.accidentDate)).format("MM/DD/YYYY") : ''}
          </span>
        </div>
      ),
      width: "80px",
      sortable: true,
    },
    {
      name: "DOS",
      selector: (row) => row?.dateOfService,
      sortable: true,
      width: "80px",
      cell: (row) => (
        <div style={{ textAlign: "right", width: "100%" }}>
          <span className="text-gray-700 font-sans">
            {row?.dateOfService ? moment(new Date(row?.dateOfService)).format("MM/DD/YYYY") : ''}
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row?.caseStatus,
      sortable: true,
      width: "80px",
      cell: (row) => <span className="text-gray-700 font-sans text-left">{row?.caseStatus}</span>,
    },
    {
      name: "Visit Status",
      selector: (row) => row?.visitStatus,
      sortable: true,
      width: "100px",
      cell: (row) => <span className="text-gray-700 font-sans text-left">{row?.visitStatus}</span>,
    },
    {
      name: "Type",
      selector: (row) => row?.caseType,
      sortable: true,
      width: "80px",
      cell: (row) => <span className="text-gray-700 font-sans text-left">{row?.caseType}</span>,
    },
    {
      name: "Provider",
      selector: (row) => row?.providerOfficeName,
      cell: (row) => (
        <span className="text-gray-700 font-sans text-left" >{row?.providerOfficeName}</span>
      ),
      width: "150px",
      sortable: true,
    },
    {
      name: "Office",
      selector: (row) => row?.providerOfficeAddress,
      sortable: true,
      width: "130px",
      cell: (row) => <span className="text-gray-700 font-sans text-left">{row?.providerOfficeAddress}</span>,
    },
    {
      name: "Insurance",
      selector: (row) => row?.insuranceName,
      cell: (row) => (
        <span className="text-gray-700 font-sans text-left">{row?.insuranceName}</span>
      ),
      width: "130px",
      sortable: true,

    },
    {
      name: "Claim#",
      selector: (row) => row?.claimNumber,
      sortable: true,
      cell: (row) => <span className="text-gray-700 font-sans text-left">{row?.claimNumber}</span>,
    },
    {
      name: "Policy#",
      sortable: true,
      selector: (row) => row?.policyNumber,
      cell: (row) => (
        <span className="text-gray-700 font-sans text-left">{row?.policyNumber}</span>
      ),
    },
    {
      name: "Procedures",
      selector: (row) => row.age,
      sortable: true,
      center: true,
      cell: (row) => <span className="text-gray-700 font-sans">
        {row?.procedureCodes &&
          row.procedureCodes.length >
          0 &&
          row.procedureCodes.map(
            (item, index) => (
              <>
                <span

                  key={index}
                >
                  {`${item.typeCode}-${item.typeDescription}`}
                </span>
                <br />
              </>
            )
          )}</span>,
    },
  ];

  const getListData = async (pageIndex = 1, pageSize = 25, searchValues) => {
    // if (showLoader()) return; // Prevent double API calls if already loading
    showLoader()
    let startDate = searchValues?.fromDate;
    let endDate = searchValues?.toDate;

    const payload = {
      "userUid": profile.userUid,
      "pageIndex": pageIndex.toString(),
      "pageSize": pageSize.toString(),
      "duration": searchValues?.duration,
      "startDate": searchValues?.duration ? '' : (startDate === null || startDate === undefined)
        ? moment(startOfMonth(addMonths(new Date(), -3))).format('MM-DD-YYYY')
        : moment(startDate).format('MM-DD-YYYY'),
      "endDate": searchValues?.duration ? '' : (endDate === null || endDate === undefined)
        ? moment(new Date()).format('MM-DD-YYYY')
        : moment(endDate).format('MM-DD-YYYY'),
      "status": arrayToCommaSeparatedString(searchValues?.selectedStatus),
      "ProviderOffice": '',
      "caseType": arrayToCommaSeparatedString(searchValues?.selectedCaseType),
      'searchKeyword': searchValues?.searchText

    }
    try {
      let result = await getPatientList(payload);
      if (result != null) {
        if (!result?.success) {
          hideLoader()
          toast.error("Something went wrong!");
        } else {
          if (result.success) {
            setPatientListData(result?.response?.receiveObj?.data?.data)
          };
          hideLoader()
        }
      }
      else {
        hideLoader()
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader()
      toast.error(error?.response?.receiveObj?.message || "Something went wrong!");
      console.log(error);

    }
  }

  const arrayToCommaSeparatedString = (array) => {
    return Array.isArray(array) ? array.join(',') : '';
  };

  // useEffect for initial page load and whenever searchValues, pageIndex, or pageSize change
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render to avoid double call
      isFirstRender.current = false;
    } else {
      getListData(pageIndex, pageSize, searchValues);
    }
  }, [pageIndex, pageSize, searchValues]);// Dependencies include searchValues, pageIndex, and pageSize


  useEffect(() => {
    caseTypeList()
    getListData(pageIndex, pageSize, searchValues);
  }, []);//




  const handlePageIndexChange = (index) => {
    setPageIndex(index);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };


  const onSearchChange = (newSearchValues) => {
    setPageIndex(1); // Reset to first page when search values change
    setSearchValues((prevValues) => {
      const prevSelectedValues = {
        selectedStatus: prevValues.selectedStatus,
        selectedCaseType: prevValues.selectedCaseType,
        searchText: prevValues.searchText,
        fromDate: prevValues.fromDate,
        duration: prevValues.duration


      };

      const updatedSearchValues = {
        ...prevSelectedValues,
        ...newSearchValues,
      };

      return updatedSearchValues;
    });
  };

  const handleGenerateExcel = async () => {
    showLoader()
    const payload = {
      "userUid": profile.userUid,
      "pageIndex": "1",
      "pageSize": patientListData?.totalCount?.toString(),
    }
    try {
      let result = await getPatientList(payload);
      if (result != null) {
        if (!result?.success) {
          toast.error("Something went wrong!");
          hideLoader()
        } else {
          if (result.success) {
            const data = result?.response?.receiveObj?.data?.data?.patientReferralReportRecords;

            // Check if data exists
            if (data) {
              // Format date fields (doa, dob, dos) in the data
              const formattedData = data.map(record => ({
                ...record,
                doa: record.doa
                  ? moment(record.doa).format("MM/DD/YYYY") // Format 'doa' to dd/mm/yyyy
                  : record.doa,
                dob: record.dob
                  ? moment(record.dob).format("MM/DD/YYYY") // Format 'dob' to dd/mm/yyyy
                  : record.dob,
                dos: record.dos
                  ? moment(record.dos).format("MM/DD/YYYY") // Format 'dos' to dd/mm/yyyy
                  : record.dos,
              }));
              arrayToExcel.convertArrayToTable(
                reportsTableHeaders,
                formattedData,
                "PatientReport.xlsx"
              );
            }
          }

          hideLoader()

        }
      }
      else {
        hideLoader()
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader()
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <div className="row" style={{ zIndex: 1 }}>
        <div className="col-12 col-sm-12 ">
          <div className=" mx-auto">
            <div className="filterDesignFix" >
              <PatientListFilters
                onSearchChange={onSearchChange}
                caseType={casTypeData}

              />
            </div>
          </div>
          <div className="col-12 col-sm-12 ">
            {patientListData?.patientReferralReportRecords?.length > 0 && (
              <Pagination
                className="pagination-bar"
                totalCount={patientListData?.totalCount}
                pageSize={patientListData?.pageSize}
                currentPage={patientListData?.pageIndex}
                onPageIndexChange={(value) => handlePageIndexChange(value)}
                onPageSizeChange={(value) => handlePageSizeChange(value)}
                HeaderComponent={
                  <>
                    <div className='mr-5 custom-mb'>
                      <i className="fas fa-file-excel text-cadetblue text-3xl cursor-pointer" title="Excel" onClick={() => handleGenerateExcel()}></i>
                    </div>
                  </>
                }
              />
            )}

          </div>
          <div>
            <Table columns={columns} data={patientListData?.patientReferralReportRecords} highlightOnHover />
          </div>
        </div>
      </div>

      {isOpen && (
        <PatientDetailsModal
          closeModal={() => closeModal()}
          isOpen={isOpen}
          caseNo={selectedRow?.caseNo}
          userUid={profile.userUid}
          selectedRow={selectedRow}
        />
      )
      }

      {
        isOpenDocument &&
        <DocumentManager
          setIsModalOpen={() => closeDocumentModal()}
          isModalOpen={isOpenDocument}
          caseNo={selectedRow?.caseNo}
          caseId={selectedRow?.caseId}
          userUid={profile.userUid}
          companyId={selectedRow?.companyId}
          currentTableRow={selectedRow}
        />
      }

    </>
  );
};

export default PatientList;
