import { getFromCommmonService } from "../apiUtils";

const baseURL = process.env.REACT_APP_API_URL

export const getCaseType = async (data) => {

  return new Promise((resolve, reject) => {
    getFromCommmonService(
      `${baseURL}Report/GetCaseTypes/?UserUid=${data.UserUid}`,
      "",
      false
    )
      .then((response) => {
        return resolve({ success: true, response: response });
      })
      .catch((error) => {
        return reject({ success: false, response: error });
      });
  });
};