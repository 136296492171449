import { Component } from "react";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const predefinedRanges = [
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    appearance: "default",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    appearance: "default",
  },
  {
    label: "Last 3 month",
    value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(new Date())],
    appearance: "default",
  },
  {
    label: "This year",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    appearance: "default",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    appearance: "default",
  },
];

class DateRange extends Component {
  render() {
    const { label, onChangeDate, value, width, range } = this.props;

    // Fallback to today's range if range is not provided
    const fromDate = startOfMonth(addMonths(new Date(), -3));
    const toDate = new Date();
    const defaultRange = range || [fromDate, toDate];

    return (
      <>
        <label htmlFor="staticEmail2" className="sr-only1">
          {label || "Date"}
        </label>
        <Stack direction="column" spacing={8} alignItems="flex-start">
          <DateRangePicker
            format="MM/dd/yyyy"
            onChange={(val) => onChangeDate(val)}
            ranges={predefinedRanges}
            appearance="default"
            placeholder="Select Date Range"
            style={{ width: width || "215px" }}
            title={value || ""}
            defaultValue={defaultRange}
          />
        </Stack>
      </>
    );
  }
}

export default DateRange;


